export default defineNuxtPlugin(() => {
  const authStore = useAuthStore()
  const userStore = useUserStore()

  const {
    public: { http: httpConfig, verificationUrl },
  } = useRuntimeConfig()

  const prefixUrl = httpConfig.browserBaseURL

  const route = useRoute()

  const api = $fetch.create({
    baseURL: prefixUrl,
    onRequest({ options }) {
      if (authStore.token.value) {
        const headerValue = `${authStore.authenticationType} ${authStore.token.value}`
        options.headers.set('Authorization', headerValue)
      }

      if (options.body && Object.getPrototypeOf(options.body) === Object.prototype) {
        options.headers.set('Content-Type', 'application/json')
      }
    },
    onResponse({ response }) {
      // FIXME: The backend does not provide correct content type headers, so we need to force-parse responses and cannot check for that header here
      if (response.ok && response._data) {
        if (typeof response._data === 'string') {
          try {
            response._data = JSON.parse(response._data)
          } catch {
            // Looks like the response is not JSON, so we don't need to do anything
          }
        }

        if (Object.getPrototypeOf(response._data) === Object.prototype || Array.isArray(response._data)) {
          response._data = objectToCamelCase(response._data)
        }
      }
    },
    onResponseError({ response, options }) {
      if (response?.status && response.status >= 500 && route.name !== 'sorry') {
        // We want to give the server some time to recover and react locally if it doesn't
        if (response.status === 503 || (options.retry && options.retry > 0)) return
        navigateTo('/sorry')
      } else if (response?.status === 401) {
        if (route.name !== 'logout' && route.name !== 'login') {
          sessionStorage.setItem('redirectURL', route.fullPath)
        }
        authStore.removeStoredToken()
        void userStore.logoutUser()
      }
    },
  })

  const verificationApi = api.create({
    baseURL: verificationUrl,
    onRequest() {
      // We don't need the request interceptor from the base API, so we override it here
    },
  })

  return {
    provide: {
      api,
      verificationApi,
    },
  }
})
